/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { Injectable, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { WindowUtils } from '../utils/window.util';
import { Observable, Observer, Subject, Subscription } from 'rxjs';
import { Chatbase } from '../models/chatbase.model';
import { logChatbaseEngagement } from '@app/core/services/google-analytics.lazy';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';

const CHATBASE_ELEMENT_ID = 'popsy-chatbase';
const CHATBASE_SCRIPT_TAG = `
(function(){if(!window.chatbase||window.chatbase("getState")!=="initialized"){window.chatbase=(...arguments)=>{if(!window.chatbase.q){window.chatbase.q=[]}window.chatbase.q.push(arguments)};window.chatbase=new Proxy(window.chatbase,{get(target,prop){if(prop==="q"){return target.q}return(...args)=>target(prop,...args)}})}const onLoad=function(){const script=document.createElement("script");script.src="https://www.chatbase.co/embed.min.js";script.id="1GTZW5OH9pxU9t_EasBbu";script.domain="www.chatbase.co";document.body.appendChild(script)};if(document.readyState==="complete"){onLoad()}else{window.addEventListener("load",onLoad)}})();
`;

@Injectable({
  providedIn: 'root',
})
export class ChatbaseService {
  private readonly document: Document | null;
  private installingChatbaseSubject: Subject<Chatbase | null> | null;
  private loggedInitialEvent = false;

  constructor(
    private readonly injector: Injector,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly analyticsService: GoogleAnalyticsService
  ) {
    this.installingChatbaseSubject = null;

    if (this.akitaRouterQuery.isBrowser) {
      try {
        this.document = this.injector.get<Document | null>(DOCUMENT, null);
      } catch (err) {
        this.document = null;
      }
    } else {
      this.document = null;
    }
  }

  private get chatbaseInstance(): Chatbase | null {
    const windowRef: any | null = WindowUtils.window;
    if (windowRef && windowRef.chatbase) {
      return windowRef.chatbase as Chatbase;
    }
    return null;
  }

  public setVisitorData(
    id?: string | null,
    name?: string | null,
    phone?: string | null,
    email?: string | null,
    city?: string | null,
    country?: string | null,
    tags?: Array<string> | null
  ): void {
    if (this.akitaRouterQuery.isBrowser) {
      const userInfo = {
        ...((window as any).chatbaseIdentify || {}),
      };

      if (id) {
        userInfo.distinct_id = id;
      }

      if (name) {
        userInfo.name = name;
      }

      if (email) {
        userInfo.email = email;
      }

      if (phone) {
        userInfo.phone = phone;
      }

      if (city) {
        userInfo.city = city;
      }

      if (country) {
        userInfo.country = `${country}`.toUpperCase();
      }

      if (tags) {
        const newTags = [...(userInfo.tags || [])];
        for (const tag of tags) {
          if (!newTags.includes(tag)) {
            newTags.push(tag);
          }
        }
        userInfo.tags = newTags;
      }

      try {
        (window as any).chatbaseIdentify = userInfo;

        if ((window as any)?.chatbaseChatApi?.setVisitorData) {
          (window as any).chatbaseChatApi.setVisitorData(userInfo);
        }
      } catch (err) {}
    }
  }

  public addVisitorTags(tags?: Array<string> | null): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        if (tags && (window as any)?.chatbaseChatApi?.setVisitorData) {
          (window as any).chatbaseChatApi.addVisitorTags(tags);
        }
      } catch (err) {}
    }
  }

  public uninstallChatbase(): void {
    if (this.document) {
      if (this.chatbaseInstance) {
        const windowRef: any | null = WindowUtils.window;
        if (windowRef && windowRef.chatbaseChatApi) {
          windowRef.chatbaseChatApi = null;
        }
      }

      const elementRef = this.document.getElementById(CHATBASE_ELEMENT_ID);
      if (elementRef) {
        elementRef.remove();
      }
    }
  }

  public open(): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        if ((window as any)?.chatbaseChatApi?.setVisitorData) {
          (window as any).chatbaseChatApi.open();
        }
      } catch (err) {}
    }
  }

  public close(): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        if ((window as any)?.chatbaseChatApi?.setVisitorData) {
          (window as any).chatbaseChatApi.close();
        }
      } catch (err) {}
    }
  }

  public display(shouldDisplay?: boolean | null): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        if ((window as any)?.chatbaseChatApi?.setVisitorData) {
          (window as any).chatbaseChatApi.display(shouldDisplay);
        }
      } catch (err) {}
    }
  }

  public track(name?: string | null): void {
    if (this.akitaRouterQuery.isBrowser) {
      try {
        if (name && (window as any)?.chatbaseChatApi?.setVisitorData) {
          (window as any).chatbaseChatApi.track(name);
        }
      } catch (err) {}
    }
  }

  public installChatbase(): Observable<Chatbase | null> {
    const subscription = new Subscription();
    return new Observable((observer: Observer<Chatbase | null>) => {
      if (this.document) {
        if (this.installingChatbaseSubject && !this.installingChatbaseSubject.closed) {
          subscription.add(
            this.installingChatbaseSubject.asObservable().subscribe({
              next: (instance: Chatbase | null) => {
                observer.next(instance);
                observer.complete();

                if (subscription) {
                  subscription.unsubscribe();
                }
              },
              error: (error: unknown) => {
                observer.error(error);
                observer.complete();

                if (subscription) {
                  subscription.unsubscribe();
                }
              },
            })
          );
        } else {
          if (this.chatbaseInstance) {
            observer.next(this.chatbaseInstance);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          }

          const elementRef = this.document.getElementById(CHATBASE_ELEMENT_ID);
          if (elementRef) {
            elementRef.remove();
          }

          const headElement = this.document.getElementsByTagName('head');
          let parentElement = null;
          if (headElement.length > 0) {
            parentElement = headElement[0];
          } else {
            parentElement = this.document.getElementsByTagName('body')[0];
          }

          const script = this.document.createElement('script');
          script.setAttribute('id', CHATBASE_ELEMENT_ID);
          script.setAttribute('nonce', 'f80792a92d361e3e105bc4f22e8ce125');
          script.setAttribute('type', 'text/javascript');
          script.setAttribute('charset', 'utf-8');
          script.setAttribute('async', 'true');

          script.text = CHATBASE_SCRIPT_TAG;

          /*  script.addEventListener('load', () => {
            console.log('Chatbase loaded');
            const instance = this.chatbaseInstance;
            if (
              this.installingChatbaseSubject &&
              !this.installingChatbaseSubject.closed
            ) {
              this.installingChatbaseSubject.next(instance);
              this.installingChatbaseSubject.complete();
              this.installingChatbaseSubject = null;
            }
            console.log('Chatbase loaded');

            observer.next(instance);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          }); */

          /*   script.addEventListener('error', (error: ErrorEvent) => {
            if (
              this.installingChatbaseSubject &&
              !this.installingChatbaseSubject.closed
            ) {
              this.installingChatbaseSubject.error(error);
              this.installingChatbaseSubject = null;
            }
            observer.error(error);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          });
 */
          script.addEventListener('user-message', () => {
            console.log('User message received');
            if (!this.loggedInitialEvent) {
              console.log('Chatbot opened');
              this.loggedInitialEvent = true;
            }
            // Add your custom logic here
          });

          // Initialize
          const windowRef: any | null = WindowUtils.window;
          if (windowRef) {
            // Start Loading
            this.installingChatbaseSubject = new Subject();
            parentElement?.appendChild(script);

            // add event listener
            windowRef.chatbase.addEventListener('user-message', (event: any) => {
              if (!this.loggedInitialEvent) {
                logChatbaseEngagement(
                  this.analyticsService.logEventWrapper,
                  this.akitaRouterQuery.country,
                  event.data.content
                );
                this.loggedInitialEvent = true;
              }
            });
          } else {
            observer.next(null);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          }
        }
      } else {
        observer.next(null);
        observer.complete();

        if (subscription) {
          subscription.unsubscribe();
        }
      }
    });
  }
}
